import React from 'react'
import PropTypes from 'prop-types'
import Picture from '@components/Picture'
import styles from './PartnersAndTeam.module.scss'
import classnames from 'classnames/bind'
import Text from '@components/Text'
import GradientText from '@components/GradientText'
import {
  gradientColor1,
  gradientColor2,
  gradientColor3,
  gradientStop1,
  gradientStop2,
  gradientStop3,
} from '@styles/variables.module.scss'

const cx = classnames.bind(styles)

const PartnersAndTeam = ({ data }) => {
  return (
    <div className={cx('container')}>
      <div className={cx('section-container')}>
        <div className={cx('partners-text-container')}>
          <div className={cx('header-text')}>
            <GradientText
              tag="h1"
              className={cx('header')}
              type="h1"
              colors={[gradientColor1, gradientColor2, gradientColor3]}
              colorStops={[gradientStop1, gradientStop2, gradientStop3]}
            >
              {'Our'}
            </GradientText>
            <GradientText
              tag="h1"
              className={cx('header', 'neg-margin-top')}
              type="h1"
              colors={[gradientColor1, gradientColor2, gradientColor3]}
              colorStops={[gradientStop1, gradientStop2, gradientStop3]}
            >
              {`partnerships`}
            </GradientText>
          </div>
          <Text tag="b1" type="b1" className={cx('description-text')}>
            {data.partnershipsDescription.partnershipsDescription}
          </Text>
          <a className={cx('link')} href={data.partnershipsLink.url}>
            {`Learn more here`}
          </a>
        </div>
        <div className={cx('circle-img-container')}>
          <Picture
            className={cx('circle-img')}
            src={data.partnershipsImage.file.url}
            alt={data.partnershipsImage.description}
          />
        </div>
        <div className={cx('partners-text-container-copy')}>
          <div className={cx('header-text')}>
            <GradientText
              tag="h1"
              className={cx('header')}
              type="h1"
              colors={[gradientColor1, gradientColor2, gradientColor3]}
              colorStops={[gradientStop1, gradientStop2, gradientStop3]}
            >
              {'Our'}
            </GradientText>
            <GradientText
              tag="h1"
              className={cx('header', 'neg-margin-top')}
              type="h1"
              colors={[gradientColor1, gradientColor2, gradientColor3]}
              colorStops={[gradientStop1, gradientStop2, gradientStop3]}
            >
              {`partnerships`}
            </GradientText>
          </div>
          <Text tag="b1" type="b1" className={cx('description-text')}>
            {data.partnershipsDescription.partnershipsDescription}
          </Text>
          <a className={cx('link')} href={data.partnershipsLink.url}>
            {`Learn more here`}
          </a>
        </div>
      </div>
      <div className={cx('section-container')}>
        <div className={cx('circle-img-container')}>
          <Picture className={cx('circle-img')} src={data.teamImage.file.url} alt={data.teamImage.description} />
        </div>
        <div className={cx('team-text-container')}>
          <div className={cx('header-text')}>
            <GradientText
              tag="h1"
              className={cx('header')}
              type="h1"
              colors={[gradientColor1, gradientColor2, gradientColor3]}
              colorStops={[gradientStop1, gradientStop2, gradientStop3]}
            >
              {'Meet'}
            </GradientText>
            <GradientText
              tag="h1"
              className={cx('header', 'neg-margin-top')}
              type="h1"
              colors={[gradientColor1, gradientColor2, gradientColor3]}
              colorStops={[gradientStop1, gradientStop2, gradientStop3]}
            >
              {`our leaders`}
            </GradientText>
          </div>
          <Text tag="b1" type="b1" className={cx('description-text')}>
            {`
            Group 1001 and its companies are led by a committed team of industry experts aligned in corporate 
            mission and vision who are focused on building next generation financial services businesses.
                `}
          </Text>
          <a className={cx('link')} href={data.teamLink.url}>
            {`Learn more here`}
          </a>
        </div>
      </div>
    </div>
  )
}

PartnersAndTeam.propTypes = {
  data: PropTypes.object.isRequired,
}

export default PartnersAndTeam
